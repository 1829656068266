<template>
  <div class="about border-l-red-500 
    grid-cols-1 grid-cols-2 grid-cols-3
    grid-cols-4 grid-cols-5 grid-cols-6
    grid-cols-7 grid-cols-8 grid-cols-9
    grid-cols-10 grid-cols-11 grid-cols-12
    sm:grid-cols-1 sm:grid-cols-2 sm:grid-cols-3
    sm:grid-cols-4 sm:grid-cols-5 sm:grid-cols-6
    sm:grid-cols-7 sm:grid-cols-8 sm:grid-cols-9
    sm:grid-cols-10 sm:grid-cols-11 sm:grid-cols-12
    md:grid-cols-1 md:grid-cols-2 md:grid-cols-3
    md:grid-cols-4 md:grid-cols-5 md:grid-cols-6
    md:grid-cols-7 md:grid-cols-8 md:grid-cols-9
    md:grid-cols-10 md:grid-cols-11 md:grid-cols-12
    bg-white
    bg-green-100 bg-green-300
    bg-orange-100 bg-orange-300
    bg-red-100 bg-red-300
    bg-blue-100 bg-blue-300
    bg-purple-100 bg-purple-300
    hover:bg-green-100 hover:bg-green-300
    hover:bg-orange-100 hover:bg-orange-300
    hover:bg-red-100 hover:bg-red-300
    hover:bg-blue-100 hover:bg-blue-300
    hover:bg-purple-100 hover:bg-purple-300
    border-green-300
    border-orange-300
    border-red-300
    border-blue-300
    border-purple-300
  ">
    <h1>This is an about page</h1>
  </div>
</template>
